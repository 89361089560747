.app__CTA {
  padding: 2rem 4rem;
  border: 1px solid var(--text-color);
  background: var(--color-black);
}

.app__CTA-heading {
  text-align: center;
}

.app__CTA-input {
  flex-direction: row;
  margin-top: 3rem;
}

.app__CTA-input input ,.app__CTA-input textarea,.app__CTA-input select {
  width: 620px;
  border: 1px solid var(--text-color);
  border-radius: 5px;
  font-size: 1rem;
  font-family: var(--font-base);
  color: var(--color-white);
  margin-right: 2rem;
  padding: 0.75rem 1rem;
  background: var(--color-black);
}
.letter{
  height: 150px;
  text-wrap:wrap;
}
.box{
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}
.app__CTA-input button {
  width: max-content;
}

@media screen and (min-width: 2000px) {
  .app__CTA-input input,.app__CTA-input textarea,.app__CTA-input select {
    font-size: 2rem;
  }
}
@media screen and (max-width: 990px) {
  .app__CTA-input {
    flex-direction: column;
    width: 100%;
  }
  .app__CTA-input input ,.app__CTA-input textarea,.app__CTA-input select {
    margin: 0 0 2rem 0;
    width: 100%;
  }
}
@media screen and (max-width: 650px) {
  .app__CTA {
    padding: 2rem 2rem;
    width: 100%;
  }
}
@media screen and (max-width: 300px) {
  .app__CTA-heading h1 {
    font-size: 32px;
    line-height: 50px;
  }
}
