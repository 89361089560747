.app__aboutus {
  
    background-image: url(../../assets/bg.svg) !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    
  position: relative;
 
}
.app__aboutus-overlay {
  position: absolute;
  inset: 0;
}
.app__aboutus-overlay img {
  width: 391px;
  height: 415px;
  z-index: 0;
}
.app__aboutus-content {
  width: 100%;
  z-index: 2;
}

.app__aboutus-content {
  display: flex;
  flex-direction: row;
  padding-bottom: 250px;
}
.app__aboutus-content_about {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;

  text-align: right;
}

.app__aboutus-content_Success {
  margin: 2rem 4rem;
}

.app__aboutus-content_Success img {
  height: 80vh;
}

.app__aboutus-content_history {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  text-align: left;
}

.app__aboutus-content_history p,
.app__aboutus-content_about p {
  margin: 2rem 0;
  color: var(--color-black);
}
.svg-svg{
  position:sticky;
  top: 0;
  
  height: auto !important;
  z-index: 10000;
  width: 100%;
  height: 30vh !important;
}
@media screen and (max-width: 2000px) {
  .app__aboutus-content_history p,
  .app__aboutus-content_about p {
    margin: 4rem 0;
  }
}
@media screen and (max-width: 900px) {
  .app__aboutus-content {
    flex-direction: column;
  }
.app__aboutus-content_about,.app__aboutus-content_history {
  align-items: center;
  text-align: center;
}
  .app__aboutus-content_Success {
    margin: 4rem 0;
  }
  .app__aboutus-content {
    
    padding-bottom: 350px;
  }
}
@media screen and (max-width: 650px) {
  .app__aboutus-overlay img {
    width: 80%;
    height: 200px;
  }
}
