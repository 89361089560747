.app__specialMenu {
  overflow-x: hidden !important;
  flex-direction: column;
  background: var(--color-black);
}

.app__specialMenu-title {
  margin-bottom: 2rem;
  text-align: center;
}

.app__specialMenu-menu {
  width: 100%;
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.app__specialMenu-menu_heading {
  color: var(--color-white);
  font-family: var(--font-base);
  font-weight: 600;
  font-size: 45px;
  line-height: 58.5px;
  text-align: center;
  margin-bottom: 50px;
}
.app__specialMenu-menu_wine,
.app__specialMenu-menu_cocktails {
  
  width: 100%;
  flex-direction: row;
}

.app__specialMenu-menu_img {
  width: 410px;
  margin: 0 2rem;
  display: flex;
}
.app__specialMenu-menu_img img {
  width: 100%;
  height: auto;
}
.padding{
  padding-left: 100px;
}
.paddingXL{
  padding-left: 400px;
}
.app__specialMenu-menu_items {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  gap: 300px;
  overflow-x:visible;
  margin-bottom: 50px;
  scrollbar-width: none;
}
.animate {
  animation: scroll 20s linear infinite;
}
.animate2 {
  animation: scroll2 20s linear infinite;
}
.animate3 {
  animation: scroll3 15s linear infinite;
}
.animate:hover {
  z-index: 1000;
  animation-play-state: paused;
}
.animate2:hover {
  z-index: 1000;
  animation-play-state: paused;
}
.animate3:hover {
  z-index: 1000;
  animation-play-state: paused;
}
@media screen and (max-width: 1150px) {
  .app__specialMenu-menu {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .app__specialMenu-menu_img {
    margin: 3rem 0;
  }
}
@media screen and (max-width: 650px) { 
  .app__specialMenu-menu_img {
    width: 100%;
  }
  .app__specialMenu-menu_heading {
    font-size: 35px;
    line-height: 48.5px;
  }
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-3400px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes scroll2 {
  0% {
    transform: translateX(-3400px);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-3400px);
  }
}
@keyframes scroll3 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-1500px);
  }
  100% {
    transform: translateX(0);
  }
}