.app__header {
  background-color: var(--color-black);
  padding-top: 0px !important;
  box-shadow: -30vw 0px 0px -5px var(--side-color) inset;
  -webkit-box-shadow: -30vw 0px 0px -5px var(--side-color) inset;
  -moz-box-shadow: -30vw 0px 0px -5px var(--side-color) inset;
  padding-left: 6rem;
}

.app__header-h1 {
  font-family: var(--font-base);
  color: var(--text-color);
  letter-spacing: 0.5px;
  text-transform: capitalize;
  line-height:100px;
  font-size: 75px;
}
.app__wrapper_info {
  flex: 2;
}
.app_wrapper_img {
  flex: 1;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.app_wrapper_img .welcomeImage {
  height: 90vh;
  position: absolute ;
  top: 0;
  z-index: 1;
  border-radius: 0 0 50%  50% ;
}

.app__wrapper_info_button-container button {
  background-color: var(--side-color);

  border-radius: 50px ;
}
.app__wrapper_info_button-container button:hover {
  background-color: var(--text-color);
  transition: ease-in 0.2s;
}
@media screen and (max-width: 1150px) {
  .app__header {
    padding-top: 90px !important;
    padding-right: 90px;
  }
  
  .app__wrapper_info {
    flex: none;
    width: 60vw !important;
    display: flex !important;
    
  }
  .app_wrapper_img {
    flex: none;
    width: 100%;
    display: flex;
    justify-content: center;
  
  }
  .app_wrapper_img .welcomeImage {
    position: relative;
    
    border-radius: 15px;
    rotate: 90deg;
  }
  
}

@media screen and (max-width: 960px) {
  .app__header-h1 {
    
    letter-spacing: 0.4px;
 
    line-height:80px;
    font-size: 55px;
  }
  .app_wrapper_img {
    flex: none;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }
  .app_wrapper_img .welcomeImage {
    position: relative;
    width: 60%;
    height: auto !important;
    border-radius: 15px;
    rotate: 90deg;
  }
  
}

@media screen and (max-width: 650px) {
  .app__header{
    box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  }
  .app__wrapper_info{
    margin-top: -200px;
  }
  .app__header-h1 {
    line-height: 50px;
    font-size: 30px;
  }
  .app_wrapper_img .welcomeImage {
    display: none;
  }
}
