.app__menuitem {
  padding: 50px 80px;
  border: solid 1px var(--color-golden);
  margin: 1rem 0;
  background-color: var(--color-black);
  display: flex;
  flex-direction: column;
}
.app__menuitem:hover{
  border: solid 1px var(--text-color);
}
.app__menuitem-head {
    width: 250px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.app__menuitem-sub {
  width: 100%;
  margin-top: 0.2rem;
}

.app__menuitem-name p{
  flex: 1;
  margin-left: 30px;
  color:#DCCA87 ;
}
.app__menuitem:hover .app__menuitem-name p{
  color:  var(--text-color);
}
.app__menuitem-dash {
  width: 90px;
  height: 1px;
  background: var(--text-color);
  margin: 0 1rem;
}

.app__menuitem-price {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.app__menuitem-price p{
  background-color: var(--color-gray);
  background-clip: text;
  -webkit-text-stroke-color: transparent;
  -webkit-text-stroke-width: calc(1em/16);
  -webkit-background-clip: text;
  color: var(--color-black);
  font-size: 60px;
  line-height: 90px;
}