.app__navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-black);
  box-shadow: -30vw 0px 0px -5px var(--side-color) inset;
-webkit-box-shadow: -30vw 0px 0px -5px var(--side-color) inset;
-moz-box-shadow: -30vw 0px 0px -5px var(--side-color) inset;
  padding: 1rem 2rem;
  
}

.app__navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.app__navbar-logo img {
  width: 150px;
}
.app__navbar-links {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  z-index: 100 !important;
}

.app__navbar-links li {
  font-weight: 800 !important;
  font-size: 20px;
  margin: 0 1rem;
  cursor: pointer;
  color:var(--color-white);
}

.app__navbar-links li:hover {
  color: var(--color-grey);
  margin: 0 1rem;
  cursor: pointer;
}

.app__navbar-login {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.app__navbar-login a {
  margin: 0 1rem;
  text-decoration: none;
  transition: 0.5s ease;
  color: var(--color-black);
  z-index: 100 !important;
  font-weight: 600 !important;
  font-size: 20px;
}
.app__navbar-login a:hover {
  border-bottom: 1px solid var(--text-color);
}
.app__navbar-login div {
  width: 1px;
  height: 30px;
  background-color: var(--color-black);
  z-index: 100;
}
.app__navbar-smallscreen {
  display: none;
}
.app__navbar-smallscreen_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--color-black);
  transition: 0.5s ease;
  justify-content: start !important;
  padding-top: 150px;
  flex-direction: column;
  z-index: 100000;
}

.app__navbar-smallscreen_logo{
  margin-bottom: 50px;
}

.app__navbar-smallscreen_overlay .overlay__close {
  font-size: 27px;
  color: var(--text-color);
  cursor: pointer;

  position: absolute;
  top: 20px;
  right: 20px;
}

.app__navbar-smallscreen_links {
  list-style: none;
}

.app__navbar-smallscreen_links li {
  margin: 2rem;
  cursor: pointer;
  color: var(--text-color);
  font-size: 2rem;
  text-align: center;
  font-family: var(--font-base);
}
.app__navbar-smallscreen_links li:hover {
  color: var(--color-white);
}

@media screen and (max-width: 2000px) {
  .app__navbar-logo img {
    width: 210px;
  }
}

@media screen and (max-width: 1150px) {
  .app__navbar-login a{
    color: var(--color-white);
  }
  .app__navbar-links {
    display: none;
  }
  .app__navbar-smallscreen {
    display: flex;
  }
}

@media screen and (max-width: 650px) {
  .app__navbar {
    padding: 1rem;
      box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    
  }
  .app__navbar-login {
    display: none;
  }
  .app__navbar-logo img {
    width: 110px;
  }
}
