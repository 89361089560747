.app__OurVision-content {
  display: flex;
  flex-direction: column;
  width: 100%;

  margin-top:20px;
}

.app__OurVision-content_quote {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.app__OurVision-content_quote img {
  width: 47px;
  height: 40px;
  margin: 0 1rem 1rem 0;
}

.app__OurVision-sign {
  margin-top: 3rem;
  width: 100%;
}

.app__OurVision-sign p:first-child {
  font-family: var(--font-base);
  font-weight: 400;
  font-size: 32px;
  line-height: 41px;
  color: var(--text-color);
  letter-spacing: 0.04em;
  text-transform: capitalize;
}

.app__OurVision-sign img {
  width: 250px;
  margin-top: 3rem;
}

.app__wrapper_img {
  width: 586px;
  height: 741px;
  
}
.app__OurVision-content_text{
  color: var(--color-black) !important;
}
@media screen and (max-width: 2000px) {
  .app__OurVision-sign img {
    width: 370px;
  }
}
@media screen and (max-width: 650px) {
  .app__wrapper_info {
    margin-top: 10px !important;
  }
}
